// Functional Related
import { createRouter, createWebHashHistory } from 'vue-router'
import DataService from './database-handler'
// General
import Login from './views/login.vue'
import Logout from './views/logout.vue'
import StudentPage from './views/studentpageview.vue'
import ParentPage from './views/parentpageview.vue'
import Main from './views/main.vue'
// Homework Related
import HwkEntry from './views/hwk-entry.vue'
import HwkSummary from './views/hwk-summary.vue'
import HwkTotalCount from './views/hwk-totalcount.vue'
import HwkAllRecords from './views/hwk-all.vue'
// Discipline Related
import DcpLate from './views/dcp-late.vue'
import DcpConduct from './views/dcp-conduct.vue'
import DcpAllRecords from './views/dcp-all.vue'
// ECA Related
import EcaEntry from './views/eca-entry.vue'
import EcaAllRecords from './views/eca-all.vue'
// S.1 Interview Related
import S1Interview from './views/s1-interview.vue'
import S1Itv from './views/s1-int.vue'

export default() => createRouter({
  history: createWebHashHistory(),

  routes: [
    {
      path: '/login',
      name: 'LoginView',
      component: Login,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next('/') : next() })
      }
    },
    {
      path: '/logout',
      name: 'LogoutView',
      component: Logout,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/studentview',
      name: 'StudentPageView',
      component: StudentPage,
      beforeEnter: (to, from, next) => {
        if (sessionStorage.getItem("studentSID")) {
          next()
        } else {
          next('/login')
        }
      }
    },
    {
      path: '/parentview',
      name: 'ParentPageView',
      component: ParentPage
    },
    {
      path: '/',
      name: 'MainView',
      component: Main,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/hwk/entry',
      name: 'HwkEntryView',
      component: HwkEntry,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/hwk/classsummary',
      name: 'HwkSummaryView',
      component: HwkSummary,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/hwk/totalcount',
      name: 'HwkTotalCountView',
      component: HwkTotalCount,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/hwk/viewall',
      name: 'HwkAllRecordsView',
      component: HwkAllRecords,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/dcp/late',
      name: 'DcpLateView',
      component: DcpLate,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/dcp/conduct',
      name: 'DcpConductView',
      component: DcpConduct,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/dcp/viewall',
      name: 'DcpAllRecordsView',
      component: DcpAllRecords,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/eca/entry',
      name: 'EcaEntryView',
      component: EcaEntry,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/eca/viewall',
      name: 'EcaAllRecordsView',
      component: EcaAllRecords,
      beforeEnter: (to, from, next) => {
        DataService.checkLogin({ token: sessionStorage.getItem("token") }).then(response => { (response.data.length == 1) ? next() : next('/login') })
      }
    },
    {
      path: '/s1interview/:rid',
      name: 'S1InterviewView',
      component: S1Interview,
      meta: {
        title: 'HPCCSS Interview',
        description: 'HPCCSS Interview',
      }
    },
    {
      path: '/s1int/:rid',
      name: 'S1ItvView',
      component: S1Itv,
      meta: {
        title: 'HPCCSS S1 Interview',
        description: 'HPCCSS S1 Interview',
      }
    },
  ]
})

<style scoped>
  body {
    font-family: Arial, sans-serif;
    margin: 40px;
    background-color: #f5f5f5;
  }

  .container {
    max-width: 680px;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-align: start;
  }

  h1 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }
  h2 {
    color: #333;
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: start;
  }

  ol {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    padding-left: 20px;
  }

</style>

<template>
  <div class="container">
    <h1>旅港開平商會中學</h1>
    <h2>中一自行分配學位</h2>
    <h2>（2025-2026）</h2>
    <h2>進入網上面試當天程序</h2>

    <p>歡迎！請依照以下指示透過Teams參與面試：</p>
    <ol>
      <li><b>面試當天</b>在這裡會看見前往Teams等候室的連結：<br>
          [連結將會顯示在這裡]<div v-if="roomLink"><a v-bind:href="roomLink">請按此進入</a></div><div v-if="zhErrMsg">{{ zhErrMsg }}</div></li>
      <li>請確保您有穩定的網路連線和帶有攝影機和麥克風的裝置(如平板電腦或手提電腦)。</li>
      <li>點擊上面的連結，加入Teams會議。</li>
      <li>您將被引導到Teams等候室，請耐心等待。</li>
      <li>一旦被接納進到會議室，請按老師的指示進行面試。</li>
      <li>面試結束後，您可以退出會議。</li>
    </ol>
    <p>如果您遇到任何技術問題或疑問，請聯絡學校校務處(27123107)尋求協助。</p>
    <br><br><br><br>

    <h1>Hoi Ping Chamber of Commerce Secondary School</h1>
    <h2>S1 Discretionary Places</h2>
    <h2>2025-2026</h2>
    <h2>Joining the Online Interview</h2>
    <p>Welcome! Please follow the instructions below to join the interview via Teams:</p>
    <ol>
      <li><b>On the day of the interview</b>, you will find the link to the Teams waiting room here: <br>[You will see the link here]<div v-if="roomLink"><a v-bind:href="roomLink">Please click here to enter.</a></div><div v-if="enErrMsg">{{ enErrMsg }}</div></li>
      <li>Make sure you have a stable Internet connection and a device with a camera and microphone (such as a tablet or laptop).</li>
      <li>Click on the provided link to join the Teams meeting.</li>
      <li>You will be directed to the Teams waiting room, so please be patient.</li>
      <li>Once admitted to the meeting room, please follow the instructions given by the teacher for the interview.</li>
      <li>After the interview, you can exit the meeting.</li>
    </ol>
    <p>If you encounter any technical issues or have any questions, please contact the School Office (at 27123107) for assistance.</p>

  </div>
</template>

<script>
export default {
  name: 'S1ItvView',

  data () {
    return {
      roomID: '',
      roomLink: '',
      enErrMsg: '',
      zhErrMsg: ''
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.roomID = to.params.rid

    var linkList = {
    "a01": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a01",
    "a02": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a02",
    "a03": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a03",
    "a04": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a04",
    "a05": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a05",
    "a06": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a06",
    "a07": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a07",
    "a08": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a08",
    "a09": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a09",
    "a10": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a10",
    "a11": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a11",
    "a12": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a12",
    "a13": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a13",
    "a14": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a14",
    "a15": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a15",
    "a16": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a16",
    "a17": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a17",
    "a18": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a18",
    }

    if (this.roomID.length == 3 && this.roomID.charAt(0) == 'a') {
      this.roomLink = linkList[this.roomID]
      this.enErrMsg = ''
      this.zhErrMsg = ''
    } else {
      this.enErrMsg = 'Invalid code, please check.'
      this.zhErrMsg = '輸入有誤，請檢查。'
    }

    next
  },

  beforeMount () {
    this.roomID = this.$route.params.rid

    var linkList = {
      "a01": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a01",
      "a02": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a02",
      "a03": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a03",
      "a04": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a04",
      "a05": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a05",
      "a06": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a06",
      "a07": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a07",
      "a08": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a08",
      "a09": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a09",
      "a10": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a10",
      "a11": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a11",
      "a12": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a12",
      "a13": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a13",
      "a14": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a14",
      "a15": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a15",
      "a16": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a16",
      "a17": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a17",
      "a18": "https://www.hpccss.edu.hk/en/hpccss-s1-interview-a18",
    }

    if (this.roomID.length == 3 && this.roomID.charAt(0) == 'a') {
      this.roomLink = linkList[this.roomID]
      this.enErrMsg = ''
      this.zhErrMsg = ''
    } else {
      this.enErrMsg = 'Invalid code, please check.'
      this.zhErrMsg = '輸入有誤，請檢查。'
    }
  },

}
</script>
